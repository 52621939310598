import { IconBin, IconPencil, IconPlus, IconReload } from './icons.component';
import EventBus from "../common/EventBus";
import React, { useState } from "react";

const HeaderWithButtons = props => {
  const [ searchText, setSearchText ] = useState('');

  const handleChange = event => {
    setSearchText(event.target.value);
    if (props.onSearch) {
      props.onSearch(event.target.value);
    }
  };

  return (
    <div className="flex place-content-between py-2">
      <h1 className="uppercase tracking-wide text-xl font-bold py-2">{props.text}</h1>
      {props.onSearch &&
      <input
        autoFocus
        autoComplete="off"
        type="text"
        className="border pl-2"
        placeholder={props.searchPlaceholder}
        name="s"
        value={searchText}
        onChange={handleChange}
      />}
      {props.children}
    </div>
  );
};

const IconButton = props => {
  return (
    <button type="button" onClick={props.onClick} className="cursor-pointer rounded-md px-2 py-2 bg-blue-300 hover:bg-blue-700 text-white">
      {props.children}
    </button>
  );
};

const IconButtonPlus = props => {
  return (
    <IconButton onClick={props.onClick}>
      <IconPlus/>
    </IconButton>
  );
};

const IconButtonPencil = props => {
  return (
    <IconButton onClick={props.onClick}>
      <IconPencil/>
    </IconButton>
  );
};

const IconButtonBin = props => {
  return (
    <button type="button" onClick={props.onClick} className="cursor-pointer rounded-md px-2 py-2 bg-red-300 hover:bg-red-700 text-white">
      <IconBin/>
    </button>
  );
};

const IconButtonReload = props => {
  return (
    <IconButton onClick={props.onClick}>
      <IconReload/>
    </IconButton>
  );
};

const processReservation = reservation => {
  reservation.startdate = new Date(reservation.startdate);
  reservation.enddate = new Date(reservation.enddate);
  switch (reservation.state) {
    case 'planned':
      reservation.state_nl = 'gepland';
      break;
    case 'active':
      reservation.state_nl = 'actief';
      break;
    case 'finished':
      reservation.state_nl = 'afgerond';
      break;
    default:
      reservation.state_nl = reservation.state;
  }
  return reservation;
};

const processReservations = reservations => {
  reservations.forEach(e => processReservation(e));
  return reservations;
};

const ButtonBox = (props) => {
  return (
    <div className="absolute top-2 right-2 hidden group-hover:flex flex-row gap-2">
      {props.children}
    </div>);
};

const DialogButtonBox = (props) => {
  return (
    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b gap-2">
      {props.onCancel && <button className="basic-button-cancel" type="button" onClick={props.onCancel}>
        {props.cancelText || "cancel"}
      </button>}
      {props.onSubmit && props.onSubmit === true && <button type="submit" className="basic-button-green">
        {props.submitText || "opslaan"}
      </button>}
      {props.onSubmit && props.onSubmit !== true && <button type="button" className="basic-button-green" onClick={props.onSubmit}>
        {props.submitText || "opslaan"}
      </button>}
      {props.onDelete && <button disabled={props.deleteDisabled} className="basic-button-red" type="button" onClick={props.onDelete}>
        {props.deleteText || "verwijderen"}
      </button>}
      {props.children}
    </div>
  );
};

const serviceCallWrapper = (asyncAction, resolve, reject=()=>{}) => {
  return Promise.resolve(asyncAction).then(response => {
    if (response.status === 200) {
      if (response.data.error) {
        return reject(response.data.error);
      }
      return resolve(response.data);
    }
    // handlen wat er hier moet gebeuren?
  }).catch((error) => {
    if (error.response?.status === 401) {
      EventBus.dispatch("logout");
    } else {
      return reject(error);
    }
  });
};

export {
  HeaderWithButtons,
  IconButton,
  IconButtonPlus,
  IconButtonPencil,
  IconButtonBin,
  IconButtonReload,
  processReservation,
  processReservations,
  ButtonBox,
  DialogButtonBox,
  serviceCallWrapper
};
