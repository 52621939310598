import React, { useState, useEffect } from "react";

import PartService from "../../services/part.service";
import PageBase from "../pagebase.component";
import { useNavigate } from "react-router-dom";
import { serviceCallWrapper } from '../common';
import EditPartDialog from "./editpart.dialog";
import { useContext } from "react";
import { UserContext } from '../../services/UserContext';
import { partTypes } from '../equipment/parts/parts';

const Parts = (props) => {
  const [ parts, setParts ] = useState([]);
  const navigate = useNavigate();
  const [ showAddPart, setShowAddPart ] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    serviceCallWrapper(PartService.getAll(), data => {
      setParts(data.parts);
    });
  }, []);

  const handleSearch = text => {
    serviceCallWrapper(PartService.findByName(text), data => {
      setParts(data.parts);
    });
  };

  const handleSearchEnter = () => {
    if (parts.length === 1) {
      navigate("/parts/" + parts[0]._id);
    }
  };

  const onAddPart = data => {
    serviceCallWrapper(PartService.add(data), data => {
      navigate("/parts/" + data.part._id);
    });
  };

  return (
    <PageBase
        header="Onderdelen"
        onSearch={handleSearch}
        onSearchEnter={handleSearchEnter}
        searchPlaceholder="Search parts"
        onAdd={user.isAdmin ? () => setShowAddPart(true) : null}
      >
      {showAddPart && <EditPartDialog onClose={() => setShowAddPart(false)} onSubmit={onAddPart} />}
      <div className="grid grid-cols-1 gap-2">
        <div className="text-left w-full p-2 grid grid-cols-3 font-bold">
          <p>Naam</p>
          <p>Type</p>
          <p>Apparaat</p>
        </div>
      {parts.map((part, i) =>
        <button className="text-left w-full border p-2 rounded-md shadow grid grid-cols-3" key={i} onClick={() => navigate("/parts/"+part._id)}>
          <p>{part.name}</p>
          <p>{partTypes[part.type]?.name || 'invalid'}</p>
          <p>{part.equipment && part.equipment.name}</p>
        </button>
        )}

      </div>
    </PageBase>
  );
};

export default Parts;
