import React, { useState, useEffect, useContext } from "react";

import EquipmentService from "../../services/equipment.service";
import PageBase from "../pagebase.component";
import { useNavigate } from "react-router-dom";
import EquipmentDetailsDialog from './details.dialog';
import { serviceCallWrapper } from '../common';
import { UserContext } from '../../services/UserContext';

const EquipmentList = (props) => {
  const [equipment, setEquipment] = useState([]);
  const [showAddEquipment, setShowAddEquipment] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    serviceCallWrapper(EquipmentService.getAll(), data => {
      setEquipment(data.equipment);
    });
  }, []);

  const handleSearch = text => {
    serviceCallWrapper(EquipmentService.findByName(text), data => {
      setEquipment(data.equipment);
    });
  };

  const handleSearchEnter = () => {
    if (equipment.length === 1) {
      navigate("/equipment/" + equipment[0]._id);
    }
  };

  const onSubmitNew = (data) => {
    serviceCallWrapper(EquipmentService.add(data), data => {
      navigate("/equipment/" + data.equipment._id);
    });
  };

  return (
    <PageBase
        header="Apparatuur"
        onSearch={handleSearch}
        onSearchEnter={handleSearchEnter}
        searchPlaceholder="Search equipment"
        onAdd={user.isAdmin ? () => setShowAddEquipment(true) : null}>
      {showAddEquipment && <EquipmentDetailsDialog onClose={() => setShowAddEquipment(false)} onSubmit={onSubmitNew}/>}
      <div className="grid grid-cols-1 gap-2">
      {equipment.map((e, i) =>
        <button className="text-left w-full border p-2 rounded-md shadow" key={i} onClick={() => navigate("/equipment/" + e._id)}>
          {e.name}
        </button>
        )}

      </div>
    </PageBase>
  );
};

export default EquipmentList;
