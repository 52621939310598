import React, { useState } from "react";

import { IconPlus, IconBin } from "./icons.component";
import { IconButtonPlus, IconButtonBin } from "./common.js";


const PageBase = (props) => {
  const handleKeyDown = event => {
    if (props.onSearchEnter && event.key === 'Enter') {
      props.onSearchEnter();
    }
  };

  const [ searchText, setSearchText ] = useState('');

  const handleChange = event => {
    setSearchText(event.target.value);
    if (props.onSearch) {
      props.onSearch(event.target.value);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex-1 flex gap-2">
        <span className="flex-1 text-3xl font-bold text-gray-900">{props.header}</span>
        {props.onSearch &&
        <input
          autoFocus
          autoComplete="off"
          type="text"
          className="border pl-2"
          placeholder={props.searchPlaceholder}
          name="s"
          value={searchText}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />}
      {props.onAdd && <IconButtonPlus onClick={props.onAdd}/>}
      {props.onBin && <IconButtonBin onClick={props.onBin}/>}
      </div>
      <div className="pt-2 flex-1">
        {props.children}
      </div>
    </div>
  );
};

export default PageBase;
