import React, { useState, useEffect, useCallback, useContext } from "react";

import { useForm } from "react-hook-form";
import EquipmentService from "../../services/equipment.service";
import ReservationService from "../../services/reservation.service";
import PartService from "../../services/part.service";
import PageBase from "../pagebase.component";
import EventBus from "../../common/EventBus";
import { IconMap, IconReload, IconCollapsed, IconExpanded } from "../icons.component";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { useNavigate, useParams, Link } from "react-router-dom";
import L from 'leaflet';
import ModalBase from "../modalbase.component";
import EditPartDialog from "./editpart.dialog";
import { HeaderWithButtons, IconButton, IconButtonPlus, IconButtonPencil, IconButtonBin, IconButtonReload, processReservation, processReservations, ButtonBox } from "../common.js";
import ConfirmDialog from "../confirmdialog.component";
import HardwareRow from './hardwarerow';
import EquipmentDetailsDialog from './details.dialog';
import ReservationDialog from "../common/reservation.dialog";
import { serviceCallWrapper } from '../common';
import EquipmentDeleteDialog from './delete.dialog';
import { UserContext } from '../../services/UserContext';

const ReservationRow = props => {
  const [ isEdit, setEdit ] = useState(false);
  const [ isDelete, setDelete ] = useState(false);
  const [ reservation, setReservation ] = useState(props.reservation);
  const { user } = useContext(UserContext);

  const onSubmit = data => {
    serviceCallWrapper(ReservationService.update(reservation._id, data), data => {
      setReservation(processReservation(data.reservation));
      setEdit(false);
    });
  };

  return (
    <div className="grid grid-cols-6 items-center gap-2">
      {isEdit && <ReservationDialog onClose={() => setEdit(false)} onSubmit={onSubmit} data={reservation} fixedEquipment={props.fixedEquipment}/>}
      {isDelete &&  <ConfirmDialog question={"Weet je zeker dat je deze reservering wilt verwijderen?"} onConfirm={props.onDelete} onCancel={() => setDelete(false)}/>}
      <p>
        {reservation.site
          ? (user.isAdmin) ? <Link to={"/customers/" + reservation.site.customer._id}>{reservation.site.customer.name}</Link> : reservation.site.customer.name
          : "(verwijderd)"}
      </p>
      <p>
        {reservation.site
          ? <Link to={"/sites/" + reservation.site._id}>{reservation.site.name}</Link>
          : "(verwijderd)"}
      </p>
      <p>{reservation.state_nl}</p>
      <p>{reservation.startdate.toLocaleDateString()}</p>
      <p>{reservation.enddate.toLocaleDateString()}</p>
      {props.isAdmin
      ?
      <div className="m-1 flex justify-end gap-2">
        <IconButtonPencil type="button" onClick={() => setEdit(true)}/>
        <IconButtonBin onClick={() => setDelete(true)}/>
      </div>
      :
      <p></p>}
    </div>
  );
};

const EquipmentDetail = (props) => {
  const { equipmentId } = useParams();
  const [equipment, setEquipment] = useState({});
  const [ isEditActive, setEditActive ] = useState(false);
  const [ reservations, setReservations ] = useState([]);
  const [ parts, setParts ] = useState([]);
  const [ position, setPosition ] = useState([53.2137283, 5.8508733]);
  const [ map, setmap ] = useState(null);
  const [ reservationEdit, setReservationEdit ] = useState(-2);
  const [ partAdd, setPartAdd ] = useState(false);
  const [ equipmentDelete, setEquipmentDelete ] = useState(false);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const icon = L.icon({
    iconUrl: "/map_marker.svg",
    iconSize: [36, 56],
    iconAnchor: [18, 56],
    popupAnchor:  [0, -56]
  });

  useEffect(() => {
    serviceCallWrapper(EquipmentService.get(equipmentId), data => {
      setEquipment(data.equipment);
      setParts(data.parts);
      setReservations(processReservations(data.reservations));
    });
  }, [equipmentId]);

  useEffect(() => {
    parts.forEach(part => {
      if (part.type === 'Rut955' && part.data?.gps?.latitude && part.data?.gps?.longitude) {
        setPosition([part.data.gps.latitude, part.data.gps.longitude]);
        if (map) {
          map.panTo([part.data.gps.latitude, part.data.gps.longitude]);
        }
      }
    });
  }, [ parts ]);

  const onRemovePart = (part) => {
    serviceCallWrapper(PartService.update(part._id, { equipment: null }), data => {
      serviceCallWrapper(EquipmentService.get(equipmentId), data => {
        setEquipment(data.equipment);
        setParts(data.parts);
        setReservations(processReservations(data.reservations));
      });
    });
  };

  const onSubmitDetails = useCallback((data) => {
    serviceCallWrapper(EquipmentService.update(equipmentId, data), data => {
      setEquipment(data.equipment);
    });
    setEditActive(false);
  }, [equipmentId]);

  const onSubmitPartData = useCallback((data) => {
    equipment.parts[partEdit] = data;
    serviceCallWrapper(PartService.update(parts[partEdit]._id, data), data => {
      setParts(data.equipment);
    });
    setPartEdit(-2);
  }, [equipmentId, equipment]);

  const onEquipmentDelete = () => {
    serviceCallWrapper(EquipmentService.delete(equipmentId), data => {
      navigate("/equipment");
    });
  };

  const onSubmitAddPart = data => {
    serviceCallWrapper(EquipmentService.addPart(equipmentId, data.part), data => {
      setParts(data.parts);
      setPartAdd(false);
    });
  };

  const onDeleteReservation = reservationId => {
    serviceCallWrapper(ReservationService.delete(reservationId), data => {
      serviceCallWrapper(EquipmentService.getReservations(equipmentId), data => {
        setReservations(processReservations(data.reservations));
      });
    });
  };

  return (
    <React.Fragment>
    {equipmentDelete && <EquipmentDeleteDialog onClose={() => {setEquipmentDelete(false);}} onDelete={onEquipmentDelete} parts={parts} reservations={reservations}/>}
    {isEditActive && <EquipmentDetailsDialog onClose={() => setEditActive(false)} onSubmit={onSubmitDetails} data={equipment}/>}
    {reservationEdit >= -1 &&<ReservationDialog onClose={() => {setReservationEdit(-2);}} onSubmit={onSubmitReservation} data={reservations[reservationEdit]} reservations={reservations}/>}
    {partAdd && <EditPartDialog onClose={() => setPartAdd(false)} onSubmit={onSubmitAddPart} />}
    <PageBase
        header={equipment.name}
        onBin={user.isAdmin ? () => setEquipmentDelete(true) : null}>
      <div className="grid grid-cols-3 gap-2 items-stretch group">
        <div className="block rounded-lg border shadow relative p-2">
          <IconMap/>
          <span className="font-semibold">Algemeen</span>
          <div className="">
            <p className="uppercase text-sm">State:</p>
            <p className="pl-2">{equipment.state}</p>
            <p className="uppercase text-sm">Memo:</p>
            <p className="text-xs whitespace-pre-wrap pl-2">{equipment.memo}</p>
          </div>
          {user.isAdmin && <ButtonBox>
            <IconButtonPencil onClick={() => setEditActive(true)}/>
          </ButtonBox>}
        </div>
        <div className="h-96 col-span-2 rounded-lg border shadow">
        <MapContainer center={position} zoom={13} scrollWheelZoom={true} className="h-full" whenCreated={setmap}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position} icon={icon}>
          </Marker>
        </MapContainer>
        </div>
      </div>
      <HeaderWithButtons text="Hardware">
        {user.isAdmin && <IconButtonPlus onClick={() => setPartAdd(true)}/>}
      </HeaderWithButtons>
      <div className="flex flex-col gap-2">
      {parts.map((part, i) =>
        <HardwareRow
          key={i}
          index={i}
          part={part}
          isAdmin={user.isAdmin}
          onRemovePart={() => onRemovePart(part)}/>
      )}
      </div>
      <HeaderWithButtons text="Reserveringen"/>
      <div className="grid grid-cols-1 striped">
        <div className="grid grid-cols-6 items-center gap-2">
          <p className="font-bold">Klant</p>
          <p className="font-bold">Locatie</p>
          <p className="font-bold">Status</p>
          <p className="font-bold">Startdatum</p>
          <p className="font-bold">Einddatum</p>
          <p></p>
        </div>
      {reservations.map((reservation, i) =>
        <ReservationRow
          key={i}
          reservation={reservation}
          fixedEquipment={true}
          isAdmin={user.isAdmin}
          onDelete={() => onDeleteReservation(reservation._id)}/>
      )}
        </div>
      </PageBase>
    </React.Fragment>
  );
};

export default EquipmentDetail;
