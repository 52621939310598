export const TRANSLATIONS_NL = {
  "monday": "maandag",
  "tuesday": "dinsdag",
  "wednesday": "woensdag",
  "thursday": "donderdag",
  "friday": "vrijdag",
  "saturday": "zaterdag",
  "sunday": "zondag",
  "must": "moet",
  "may": "mag",
  "disarm at": "uit om",
  "hour": "uur",
  "hour / must arm at": "uur / moet in om",
  "username": "gebruikersnaam",
  "password": "wachtwoord",
  "Login": "inloggen",
  "logging in...": "Bezig met inloggen...",
  "Network Error": "Netwerkprobleem",
  "timetable": "bloktijden"
};
