
const Detail = (props) => {
  const part = props.part;
  return (
    <div>
    <div className="grid grid-cols-3 items-start">
      <div className="grid grid-cols-2">
        <p className="col-span-2 font-bold">analog</p>
        <p>Voltage</p>
        <p>{part.data?.analog?.voltage?.toFixed(1)} V</p>
      </div>
      <div className="grid grid-cols-2">
        <p className="col-span-2 font-bold">GSM</p>
        <p>netstate</p>
        <p>{part.data?.gsm?.netstate}</p>
        <p>RxC</p>
        <p>{part.data?.gsm?.RxC}</p>
        <p>RSSI</p>
        <p>{part.data?.gsm?.RSSI} dBm</p>
        <p>RSRQ</p>
        <p>{part.data?.gsm?.RSRQ} dB</p>
        <p>RSRP</p>
        <p>{part.data?.gsm?.RSRP} dBm</p>
        <p>SINR</p>
        <p>{part.data?.gsm?.SINR} dB</p>
        <p>temperature</p>
        <p>{part.data?.gsm?.temperature} &deg;C</p>
        <p>ICCID</p>
        <p>{part.data?.gsm?.iccid}</p>
        <p>bytes sent</p>
        <p>{(part.data?.gsm?.bsent / 1024.0  / 1204.0).toFixed(1)} MB</p>
        <p>bytes received</p>
        <p>{(part.data?.gsm?.brecv / 1024.0  / 1204.0).toFixed(1)} MB</p>
      </div>
      <div className="grid grid-cols-2">
        <p className="col-span-2 font-bold">GPS</p>
        <p>latitude</p>
        <p>{part.data?.gps?.latitude} &deg;</p>
        <p>longitude</p>
        <p>{part.data?.gps?.longitude} &deg;</p>
        <p>accuracy</p>
        <p>{part.data?.gps?.accuracy} m</p>
        <p>status</p>
        <p>{part.data?.gps?.status === 1 ? 'Fix' : 'No Fix'}</p>
        <p>sattelites</p>
        <p>{part.data?.gps?.sattelites}</p>
        <p>datetime</p>
        <p>{part.data?.gps?.datetime ? (new Date(part.data.gps.datetime)).toUTCString() : ''}</p>
      </div>
    </div>
    </div>
  );
};

const Config = props => {
  return (
    <div className="p-6 grid grid-cols-4 gap-2">
      <div className="col-span-2">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="host">
          Host
        </label>
        <input className={`basic-input ${'host' in props.errors && `border-red-500`}`} type="text" autoFocus {...props.register('host')}/>
        {props.errors.host && <p className="text-red-500 text-xs italic mb-2">Dit veld is verplicht.</p>}
      </div>
    </div>
  );
};

const ConfigDisplay = props => {
  return (
    <div className="grid grid-cols-4 gap-2">
      <div className="col-span-2">
        <p className="uppercase text-sm">Host:</p>
        <p>{props.config.host}</p>
      </div>
    </div>
  );
};

const Icon = () => {
  return (
    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <g strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
        <rect x="2" y="12" width="10" height="10" rx="2" />
        <path d="M 11,9 a 4,4 0 0 1 4,4" />
        <path d="M 11,5 a 8,8 0 0 1 8,8" />
        <path d="M 11,1 a 12,12 0 0 1 12,12" />
      </g>
    </svg>
  );
};

export default { Detail, Config, ConfigDisplay, Icon };
