import React, { useState, useContext } from "react";
import { Transition } from "@headlessui/react";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from '../services/UserContext';

function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const { logout, user } = useContext(UserContext);
  const location = useLocation();
  const { pathname } = location;

  return (
      <nav className="bg-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex-1 flex items-center">
              <div className="hidden md:block flex-1">
                <div className="ml-10 flex items-baseline space-x-4">
                  <Link to="/" className={pathname === '/' ? 'navlink-active' : 'navlink-basic'}>
                    Dashboard
                  </Link>
                  {(user.isAdmin || user.isCustomer) && <Link to="/customers" className={pathname.startsWith('/customers') ? 'navlink-active' : 'navlink-basic'}>
                    Klanten
                  </Link>}
                  <Link to="/sites" className={pathname.startsWith('/sites') ? 'navlink-active' : 'navlink-basic'}>
                    Locaties
                  </Link>
                  <Link to="/equipment" className={pathname.startsWith('/equipment') ? 'navlink-active' : 'navlink-basic'}>
                    Apparatuur
                  </Link>
                  {user.isAdmin && <Link to="/parts" className={pathname.startsWith('/parts') ? 'navlink-active' : 'navlink-basic'}>
                    Onderdelen
                  </Link>}
                  {user.isAdmin && <Link to="/users" className={pathname.startsWith('/users') ? 'navlink-active' : 'navlink-basic'}>
                    Gebruikers
                  </Link>}
                  {user.isAdmin && <Link to="/reports" className={pathname.startsWith('/reports') ? 'navlink-active' : 'navlink-basic'}>
                    Reports
                  </Link>}
                  <div className="flex-1"></div>
                  <div>Logged in as: {user.username}</div>
                  <button onClick={() => logout()} className="text-gray-800 hover:bg-blue-200 px-3 py-2 rounded-md text-sm font-medium">
                    Logout
                  </button>
                </div>

              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="bg-gray-900 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div className="md:hidden" id="mobile-menu">
              <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <a
                  href="/"
                  className="hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Dashboard
                </a>

                <a
                  href="/customers"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Klanten
                </a>

                <a
                  href="/sites"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Locaties
                </a>

                <a
                  href="/equipment"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Apparatuur
                </a>

                <a
                  href="/"
                  className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Reports
                </a>
              </div>
            </div>
          )}
        </Transition>
      </nav>
  );
}

export default Nav;
