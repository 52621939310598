import React, { useCallback, useReducer, useState } from "react";

import ModalBase from "../modalbase.component";
import { DialogButtonBox } from "../common";
import { useTranslation } from "react-i18next";

function reducer(state, action) {
  const newState = { ...state, ...action };
  return newState;
}

const TimetableRow = props => {
  const [ state, setState ] = useState({
    mandatory: props.timetable[props.day]?.mandatory || 'false',
    disarm: props.timetable[props.day]?.disarm || '10:30',
    arm: props.timetable[props.day]?.arm || '18:30'
  });
  const { t, i18n } = useTranslation();

  const handleChange = event => {
    var value = event.target.value;
    switch (event.target.name) {
      case 'disarm':
        if (value.length === 4 && !value.includes(':')) {
          value = value.substring(0, 2) + ':' + value.substr(2);
        }
        break;
      case 'arm':
        if (value.length === 4 && !value.includes(':')) {
          value = value.substring(0, 2) + ':' + value.substr(2);
        }
        break;
      case 'mandatory':
        value = value === 'true';
        break;
    }
    const newState = { ...state, [event.target.name]: value };
    setState(newState);
    props.onChange({ [props.day]: newState });
  };

  return (
    <div className="grid grid-cols-timetable mb-2">
      <span className="p-2"> {t(props.day)}</span>
      <select className="basic-select" name="mandatory" value={state.mandatory} onChange={handleChange}>
        <option value="true">{t('must')}</option>
        <option value="false">{t('may')}</option>
      </select>
      <span className="p-2">{t('disarm at')}</span>
      <input className="basic-input text-center" type="text" name="disarm" value={state.disarm} onChange={handleChange}/>
      <span className="p-2">{t('hour / must arm at')}</span>
      <input className="basic-input text-center" type="text" name="arm" value={state.arm} onChange={handleChange}/>
      <span className="p-2">{t('hour')}</span>
    </div>
  );
};


const TimetableDialog = props => {
  const [ timetable, setTimetable ] = useReducer(reducer, props.timetable || {
    monday: {
      mandatory: false,
      disarm: '09:00',
      arm: '18:00'
    },
    tuesday: {
      mandatory: false,
      disarm: '09:00',
      arm: '18:00'
    },
    wednesday: {
      mandatory: false,
      disarm: '09:00',
      arm: '18:00'
    },
    thursday: {
      mandatory: false,
      disarm: '09:00',
      arm: '18:00'
    },
    friday: {
      mandatory: false,
      disarm: '09:00',
      arm: '18:00'
    },
    saturday: {
      mandatory: false,
      disarm: '09:00',
      arm: '18:00'
    },
    sunday: {
      mandatory: false,
      disarm: '09:00',
      arm: '18:00'
    },
  });

  const handleChange = useCallback(data => {
    setTimetable(data);
  }, [ setTimetable ]);

  return (
    <ModalBase title="Timetable" onClose={props.onClose} className="max-w-4xl">
      <div className="mt-2">
        <TimetableRow onChange={handleChange} day="monday" timetable={timetable}/>
        <TimetableRow onChange={handleChange} day="tuesday" timetable={timetable}/>
        <TimetableRow onChange={handleChange} day="wednesday" timetable={timetable}/>
        <TimetableRow onChange={handleChange} day="thursday" timetable={timetable}/>
        <TimetableRow onChange={handleChange} day="friday" timetable={timetable}/>
        <TimetableRow onChange={handleChange} day="saturday" timetable={timetable}/>
        <TimetableRow onChange={handleChange} day="sunday" timetable={timetable}/>

      </div>
      <DialogButtonBox onCancel={props.onClose} onSubmit={() => props.onSubmit(timetable)} submitText="opslaan"/>
    </ModalBase>
  );
};

export default TimetableDialog;
