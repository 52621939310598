import React, { useState, useEffect, useContext } from "react";

import CustomerService from "../../services/customer.service";
import PageBase from "../pagebase.component";
import EventBus from "../../common/EventBus";
import { useNavigate } from "react-router-dom";
import AddCustomerDialog from "./addcustomerdialog";
import { UserContext } from '../../services/UserContext';
import { serviceCallWrapper } from '../common';

const CustomerList = (props) => {
  const [ customers, setCustomers ] = useState([]);
  const [ showAddCustomer, setShowAddCustomer ] = useState(false);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    serviceCallWrapper(CustomerService.getAll(), data => {
      setCustomers(data.customers);
    });
  }, []);

  const handleSearch = text => {
    serviceCallWrapper(CustomerService.findByName(text), data => {
      setCustomers(data.customers);
    });
  };

  const handleSearchEnter = () => {
    if (customers.length === 1) {
      navigate("/customers/" + customers[0]._id);
    }
  };
console.log(customers);
  return (
    <PageBase
        header="Klanten"
        onSearch={handleSearch}
        onSearchEnter={handleSearchEnter}
        searchPlaceholder="Search customers"
        onAdd={user.isAdmin ? () => setShowAddCustomer(true) : null}>
      {showAddCustomer && <AddCustomerDialog onClose={() => {setShowAddCustomer(false); }} />}
      <div className="grid grid-cols-1 gap-2">
        {customers.map((e, i) =>
        <button className="text-left w-full border p-2 rounded-md shadow" key={i} onClick={() => navigate("/customers/" + e._id)}>
          {e.name}
        </button>
        )}

      </div>
    </PageBase>
  );
};

export default CustomerList;
